import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Button, Caption, Input } from '@olist/design-system';

import useAuth from 'hooks/common/useAuth';

import { InfoError, SearchIcon, WrapperInput } from './InputCode.styles';

export function InputCode() {
  const { t } = useTranslation();

  const { asPath, events, push, query } = useRouter();
  const [notFoundOrder, setNotFoundOrder] = useState(false);

  const { handleAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [codeSearch, setCodeSearch] = useState('');

  const enabledSearch = codeSearch && codeSearch.length < 30;

  const submitSearchOrder = async () => {
    if (enabledSearch) {
      setIsLoading(true);
      setNotFoundOrder(false);
      const responseAuth = await fetch('api/authentication', {
        method: 'POST',
        body: JSON.stringify({
          code: codeSearch.replace(/['-/.']/g, ''),
        }),
      });

      const auth = await responseAuth.json();

      handleAuth(auth);
      if (auth?.pathname) {
        setCodeSearch('');
        push(auth?.pathname, undefined);
      } else {
        setNotFoundOrder(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const { notFound } = query;

    if (notFound) {
      setNotFoundOrder(true);
      setIsLoading(false);
    }
  }, [query]);

  const handleKeyDown = (key: string) => {
    if (key === 'Enter' && enabledSearch) {
      submitSearchOrder();
    }
  };

  useEffect(() => {
    const handleComplete = () => {
      setIsLoading(false);
      setNotFoundOrder(false);
    };

    events.on('routeChangeComplete', handleComplete);

    return () => {
      events.off('routeChangeComplete', handleComplete);
    };
  }, [events, asPath]);

  return (
    <WrapperInput>
      <Caption>{t('Inform your CNPJ, tracking code/order number')}</Caption>
      <Input
        onChange={({ currentTarget }) => setCodeSearch(currentTarget.value)}
        suffix={() => <SearchIcon onClick={submitSearchOrder} />}
        feedback={notFoundOrder ? 'error' : undefined}
        onKeyDown={({ key }) => handleKeyDown(key)}
        required
      />

      {notFoundOrder && (
        <InfoError>
          {t(
            'The data entered does not match or no order was found. Please try if you entered your details correctly or again later.'
          )}
        </InfoError>
      )}
      <Button isLoading={isLoading} onClick={submitSearchOrder} disabled={!enabledSearch}>
        {t('accompany')}
      </Button>
    </WrapperInput>
  );
}
