import styled from 'styled-components';

import { Caption } from '@olist/design-system';
import { SearchOutline } from '@olist/design-system-icons';

const WrapperInput = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  > span {
    color: ${({ theme }) => theme.olist.color.neutral.strong};
    font-size: ${({ theme }) => theme.olist.fontSize.medium};
    text-align: center;
  }

  > div {
    padding: ${({ theme }) => theme.olist.spacing.inset.small};
    width: 100%;

    > button {
      align-self: center;
      display: flex;
      margin: ${({ theme }) => theme.olist.spacing.stack.medium} auto;
      padding: ${({ theme }) => theme.olist.spacing.inset.small};
    }
  }

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    width: 90%;
    justify-content: flex-start;
    padding: 25% 0px;

    > button {
      width: 100%;
      padding: ${({ theme }) => theme.olist.spacing.inset.small};
    }
  }
`;

const InfoError = styled(Caption)`
  color: ${({ theme }) => theme.olist.color.alert.base} !important;
  font-size: ${({ theme }) => theme.olist.fontSize['1xsmall']} !important;
  padding-bottom: ${({ theme }) => theme.olist.spacing.inset.small};
`;

const SearchIcon = styled(SearchOutline)`
  cursor: pointer;
`;

export { WrapperInput, InfoError, SearchIcon };
