import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    padding-top: ${({ theme }) => theme.olist.spacing.stack.huge};
  }

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    height: 100vh;

    h1 {
      display: none;
    }
  }
`;

export { Container };
