import { useTranslation } from 'react-i18next';

import { Title } from '@olist/design-system';

import { Container } from './Home.styles';
import { InputCode } from './InputCode';

export const Home = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('track your orders')}</Title>
      <InputCode />
    </Container>
  );
};
