import { useContext } from 'react';

import { AuthContext, IAuthContext } from 'contexts/auth';

export default function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth needs AuthProvider for use');
  }
  return context;
}
